/** @format */

export const MODE = 'DEV';
export const API_URL = 'https://camunda.api.mycorp.cyder.com.sg';
export const PROCESS_DEFINITION_KEY = 'mycorp-document-review';

export const MYCORP_FILE_API_URL = 'https://file.api.mycorp.io';
export const MYCORP_WORKFLOW_ENDPOINTS = 'https://workflow.api.mycorp.cyder.com.sg';
export const MYCORP_AUTH_ENDPOINTS = 'https://auth.api.mycorp.cyder.com.sg';
export const MYCORP_AUTHORISE_ENDPOINTS = 'https://authorise.api.mycorp.cyder.com.sg';
export const MYCORP_EXPENSES_ENDPOINTS = 'https://expense.api.mycorp.io';
export const MYCORP_NOTIFY_ENDPOINTS = 'https://notify.api.mycorp.cyder.com.sg';
export const MYCORP_AUDIT_ENDPOINTS = 'https://audit.api.mycorp.io';
export const MYCORP_CONTACT_ENDPOINTS = 'https://contact.api.mycorp.io';
// export const DJARVIS_URL = 'https://app.djarvis.page';
export const DJARVIS_URL = 'https://hr.mycorp.io';
export const DJARVIS_COMMON_API_ENDPOINT = 'https://common.api.prod.cyder.com.sg';
export const DJARVIS_LEAVE_API_ENDPOINT = 'https://leave.api.prod.djarvis.cyder.com.sg';
export const ATLASSIAN_ISSUETYPE = '10058';
export const ATLASSIAN_PROJECT = 'MCSD';
export const DJARVIS_API_KEY = 'OX0CR7p8Zd6ayqyqGI8UJ7qr8XaSDsSf6WKovUp6';
